export const formatEventFirebaseEcommerceData = (data = {}) => {
  try {
    const { ecommerce, ...otherData } = JSON.parse(JSON.stringify(data))

    return {
      ...otherData,
      ...(ecommerce ? { ecommerce } : {})
    }
  } catch (error) {
    console.error('Error formatting data:', error)
    return {}
  }
}
