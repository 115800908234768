import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFatalStore = defineStore('fatal', () => {
  const canRetry = ref(true)
  const didRetry = ref(false)
  const counter = ref(0)
  const counterIntervalId = ref<any>(null)

  // accepts a callback function that executes when the counter reaches 0
  const countdownCounter = (resetCounterFn: Function = resetCounter) => {
    canRetry.value = false
    counterIntervalId.value = setInterval(() => {
      counter.value -= 1
      if (counter.value === 0) {
				if (resetCounterFn) {
					resetCounterFn()
				}
				clearInterval(counterIntervalId.value)
        counterIntervalId.value = null
      }
    }, 1000)
  }

  const resetCounter = (newCounter: number = 5) => {
    canRetry.value = true
    counter.value = newCounter
  }

  return { canRetry, didRetry, counter, counterIntervalId, countdownCounter, resetCounter }
})
