import { createApp, h } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Fatal from './AppFatal.vue'
import sentryFullStoryPlugin from './vendor/SentryFullStory'
import FontAwesomeIcon from './vendor/fontAwesome'
import { VueHeadMixin, createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

const head = createHead()

export function shutdown ({ reason }) {
  const app = createApp({
    render () {
      return h(Fatal, {
        failure: reason
      })
    }
  })
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/shared/Fatal.vue')
      }
    ]
  })

  const pinia = createPinia()
  pinia.use(createPersistedState({
    auto: true,
    key: id => `pinia_${id}`,
  }))
  
  app.use(head)
  app.mixin(VueHeadMixin)
  app.use(router)
  app.use(pinia)
  app.use(sentryFullStoryPlugin)
  app.use(FontAwesomeIcon)
  app.mount('#fatal-root')
}