import { PRODUCT } from '@/config/constants'

import { useCartStore } from '@/stores/cart'

export const getAlcoholUnits = basketItems => basketItems.reduce((total, bi) => (bi.alcoholUnits * bi.quantity) + total, 0)
export const getTotalAlcoholUnits = () => {
  const totalAlcoholUnits = getAlcoholUnits(useCartStore().basketItems) + useCartStore().groupedBasketItems.reduce((total, gbi) => (getAlcoholUnits(gbi.basketItems) * gbi.quantity) + total, 0)
  return totalAlcoholUnits
}
export const hasSauce = (product) => product.ingredients?.some(i => i.ingredientCategoryCode === PRODUCT.INGREDIENT_CATEGORY.SAUCE)
export const isCustomisable = (product) => product.discriminator === PRODUCT.DISCRIMINATOR.CUSTOMISABLE_PRODUCT
export const isStockItemExtended = product => product.productType === PRODUCT.PRODUCT_TYPE.STOCK_ITEM_EXTENDED
export const isCustomisableAndHasSauce = (product) => isCustomisable(product) && hasSauce(product)
export const isStockItemWithNoSauce = (product) => !hasSauce(product) && isStockItemExtended(product)
export const filterPizzasWithNoSauce = (products) => {
  const filtered = products.filter(product => 
    !isCustomisable(product) || isCustomisableAndHasSauce(product) || isStockItemWithNoSauce(product)
  )
  return filtered
}
